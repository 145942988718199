<app-loading-tombstone
	*ngIf="loading"
	[morePadding]="true"></app-loading-tombstone>

<mat-card
	appearance="outlined"
	*ngIf="!!task"
	class="details"
	[ngClass]="{
		'card-disabled': task.status == TaskStatus.disabled,
		'card-completed': task.status == TaskStatus.completed
	}">
	<!-- <mat-card-header  > -->
	<mat-card-header>
		<mat-card-title
			class="top-title"
			style="margin-right: -1rem; margin-left: -1rem; margin-top: 0.5rem">
			<button mat-icon-button (click)="openParent()" class="hide-large">
				<mat-icon>arrow_back</mat-icon>
			</button>
			<button mat-button (click)="openParent()" class="hide-small">
				<mat-icon>arrow_back</mat-icon> Back
			</button>
			<mat-icon
				[ngClass]="{
					'icon-not-started': task.status == TaskStatus.notStarted,
					'icon-in-progress': task.status == TaskStatus.inProgress,
					'icon-completed': task.status == TaskStatus.completed,
					'icon-disabled': task.status == TaskStatus.disabled,
					'icon-canceled': task.status == TaskStatus.cancelled
				}"
				mat-list-icon
				>{{
					task.taskType == TaskType.decider
						? "signpost"
						: task.taskType == TaskType.milestone
						? "beenhere"
						: "task"
				}}</mat-icon
			>
			<div class="spacer"></div>
			<mat-progress-spinner
				style="
					padding: 0.5rem;
					border-radius: 2rem;
					position: absolute;
					top: 1rem;
					right: 1rem;
					font-size: 1rem;
				"
				*ngIf="loading"
				diameter="24"
				mode="indeterminate"
				color="primary"></mat-progress-spinner>

			<mat-chip-listbox aria-label="Task Status">
				<mat-chip-option
					*ngIf="task.status == TaskStatus.inProgress"
					class="chip-in-progress chip-button"
					(click)="changeStatus(TaskStatus.notStarted)"
					selectable="false"
					highlighted
					>In Progress</mat-chip-option
				>
				<mat-chip-option
					*ngIf="task.status == TaskStatus.notStarted"
					class="chip-not-started chip-button"
					(click)="changeStatus(TaskStatus.inProgress)"
					selectable="false"
					highlighted
					>To Do</mat-chip-option
				>
				<mat-chip-option
					*ngIf="task.status == TaskStatus.disabled"
					class="chip-disabled chip-button"
					selectable="false"
					highlighted
					>Blocked</mat-chip-option
				>
				<mat-chip-option
					*ngIf="task.status == TaskStatus.completed"
					class="chip-completed chip-button"
					selectable="false"
					highlighted
					>Completed</mat-chip-option
				>
				<mat-chip-option
					*ngIf="task.status == TaskStatus.cancelled"
					class="chip-cancelled chip-button"
					selectable="false"
					highlighted
					>Canceled</mat-chip-option
				>
				<mat-chip-option
					*ngIf="task.buyersTrack"
					color="primary"
					highlighted
					selectable="false"
					>Buyer</mat-chip-option
				>
				<mat-chip-option
					style="color: #006396; background-color: #0063961a"
					*ngIf="task.sellersTrack"
					color="accent"
					highlighted
					selectable="false"
					><span style="color: #006396">Seller</span></mat-chip-option
				>
			</mat-chip-listbox>
			<button
				mat-icon-button
				[matMenuTriggerFor]="menu"
				aria-label="menu for task">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item [matMenuTriggerFor]="status">
					<mat-icon>task_alt</mat-icon>
					<span>Status</span>
				</button>
				<button mat-menu-item disabled>
					<mat-icon>https</mat-icon>
					<span>Access Rights</span>
				</button>
				<button mat-menu-item (click)="delete()" [disabled]="!canEdit">
					<mat-icon>delete</mat-icon>
					<span>Delete</span>
				</button>
			</mat-menu>
			<mat-menu #status="matMenu">
				<button mat-menu-item [matMenuTriggerFor]="controlled">
					Controlled By
				</button>
				<button mat-menu-item [matMenuTriggerFor]="state">State</button>
			</mat-menu>
			<mat-menu #controlled="matMenu">
				<button mat-menu-item class="menu-button-selected">The System</button>
				<button mat-menu-item disabled>Manually</button>
			</mat-menu>

			<mat-menu #state="matMenu">
				<button
					mat-menu-item
					[disabled]="!canEdit"
					[ngClass]="{ 'chip-completed': task.status == TaskStatus.completed }"
					(click)="changeStatus(TaskStatus.completed)">
					Completed
				</button>
				<button
					mat-menu-item
					[disabled]="!canEdit"
					[ngClass]="{
						'chip-in-progress': task.status == TaskStatus.inProgress
					}"
					(click)="changeStatus(TaskStatus.inProgress)">
					In Progress
				</button>
				<button
					mat-menu-item
					[disabled]="!canEdit"
					[ngClass]="{
						'chip-not-started': task.status == TaskStatus.notStarted
					}"
					(click)="changeStatus(TaskStatus.notStarted)">
					To Do
				</button>
				<button
					mat-menu-item
					[disabled]="!canEdit"
					[ngClass]="{ 'chip-disabled': task.status == TaskStatus.disabled }"
					(click)="changeStatus(TaskStatus.disabled)">
					Blocked
				</button>
				<button
					mat-menu-item
					[disabled]="!canEdit"
					[ngClass]="{ 'chip-cancelled': task.status == TaskStatus.cancelled }"
					(click)="changeStatus(TaskStatus.cancelled)">
					Canceled
				</button>
			</mat-menu>
		</mat-card-title>
		<mat-card-title style="justify-content: start; flex-wrap: wrap">
			<span class="address" *ngIf="task.onProperty">{{
				task.propertyAddress
			}}</span
			><span class="address" *ngIf="!task.onProperty">{{
				task.propertyNickname
			}}</span>
			<span>{{ task.title }}</span>
			<!-- <span style="flex-grow: 1"></span> -->
		</mat-card-title>
		<!-- <mat-card-subtitle *ngIf="task.description != task.title">
			{{ task.description }}
		</mat-card-subtitle> -->
	</mat-card-header>

	<!-- </mat-card-header> -->
	<mat-card-content>
		<div
			*ngIf="task.description != task.title"
			class="description"
			style="margin: 0">
			<markdown [data]="task.description"></markdown>
		</div>
		<br />
		<div class="boxed-area" *ngIf="!quickStartQuestionsCompleted">
			<mat-label
				>Quick Start Questions ({{ currentQuickStartQuestionNumber }} of
				{{ totalQuickStartQuestions }})</mat-label
			>
			<div *ngIf="saving" class="question">
				Saving<app-loading-dots></app-loading-dots>
			</div>
			<div *ngIf="!saving">
				<h2>
					<markdown [data]="currentQuickStartQuestion?.question"></markdown>
				</h2>
				<div class="questions">
					<div
						*ngFor="let a of currentQuickStartQuestion?.options"
						class="question">
						<!-- <h3>{{ a.description }}</h3> -->
						<h3><markdown [data]="a.description"></markdown></h3>
						<div>
							<button
								mat-raised-button
								type="button"
								[disabled]="saving || taskFormGroup.disabled || !canEdit"
								(click)="
									answerQuickStartQuestion(currentQuickStartQuestion, a.label)
								">
								{{ a.label }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <p *ngIf="task?.description != task?.title" >{{task?.description}}</p> -->

		<!-- <button *ngIf="task?.status != TaskStatus.completed && task?.taskType == TaskType.standard" [disabled]="task?.status == TaskStatus.disabled || loading"  mat-raised-button color="primary" (click)="complete(task)">Compete</button>
        <select *ngIf="task?.isTemplate != 'template' && task?.taskType == TaskType.decider" [disabled]="task?.status == TaskStatus.completed || task?.status == TaskStatus.disabled || loading"  [ngModel]="task?.result" (change)="changeResult(task, $event.target.value)"  >
            <option value=""></option>
            <option *ngFor="let node of task?.options" [value]="node.value">{{node.label}}</option>
        </select> -->
		<!-- <app-speech-text></app-speech-text> -->
		<form [formGroup]="taskFormGroup" (submit)="complete(task)">
			<div
				*ngIf="
					taskFormGroup.get('result') && task?.taskType == TaskType.decider
				">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Answer</mat-label>
					<mat-select formControlName="result">
						<mat-option *ngFor="let node of task?.options" [value]="node.value">
							{{ node.label }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div>
				<mat-form-field
					*ngIf="
						task?.estimatedCompletionDate &&
						(!task?.children?.length || task?.children?.length == 0)
					"
					appearance="outline"
					floatLabel="always">
					<mat-label>Estimated Completion Date</mat-label>
					<input
						matInput
						[matDatepicker]="dueDatePicker"
						formControlName="estimatedCompletionDate" />
					<mat-hint>DD/MM/YYYY</mat-hint>
					<mat-datepicker-toggle
						matSuffix
						[disabled]="taskFormGroup.get('estimatedCompletionDate')?.disabled"
						[for]="dueDatePicker"></mat-datepicker-toggle>
				</mat-form-field>
				<mat-datepicker #dueDatePicker></mat-datepicker>
				<mat-icon
					*ngIf="
						task?.estimatedCompletionDate &&
						(!task?.children?.length || task?.children?.length == 0)
					"
					#ddToolTip="matTooltip"
					(click)="ddToolTip.toggle()"
					matTooltip="This is the most likely date when others can expect this task to be completed by.  You can always change this date to better keep the rest of chain informed when things will progress."
					>info</mat-icon
				>
			</div>
			<div
				*ngIf="dataFrom"
				[FormGroup]="dataFrom"
				style="display: flex; gap: 1rem; flex-wrap: wrap">
				<ng-container
					*ngFor="let item of dataFrom?.controls | keyvalue; let i = index"
					[ngSwitch]="dataControl(item.key)?.dataType">
					<ng-container *ngSwitchCase="'money'">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>{{ item.key }}</mat-label>
							<span matTextPrefix>£&nbsp;</span>
							<input
								appMoneyInput
								matInput
								[formControl]="$any(item.value)"
								style="text-align: right"
								placeholder="0.00" />
						</mat-form-field>
					</ng-container>
					<ng-container *ngSwitchCase="'checkList'">
						<section class="example-section" [formGroup]="$any(item.value)">
							<h4>{{ item.key }}</h4>
							<p
								*ngFor="
									let controlKey of getDataPointOptions(item.key);
									let i = index
								">
								<mat-checkbox [formControlName]="controlKey">{{
									controlKey
								}}</mat-checkbox>
							</p>
						</section>
					</ng-container>
					<ng-container *ngSwitchCase="'options'">
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							class="example-section">
							<mat-label>{{ item.key }}</mat-label>
							<mat-select [formControl]="$any(item.value)">
								<mat-option
									*ngFor="
										let opt of getDataPointOptions(item.key);
										let i = index
									"
									[value]="opt">
									{{ opt }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<!-- <section class="example-section" [formGroup]="$any(item.value)">
							<h4>{{ item.key }}</h4>
							<p
								*ngFor="
									let controlKey of getDataPointOptions(item.key);
									let i = index
								">
								<mat-checkbox [formControlName]="controlKey">{{
									controlKey
								}}</mat-checkbox>
							</p>
						</section> -->
					</ng-container>
					<ng-container *ngSwitchCase="'text'">
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							style="width: 100%">
							<mat-label>{{ item.key }}</mat-label>
							<textarea
								style="width: 100%; min-height: 4rem"
								matInput
								[formControl]="$any(item.value)"></textarea>
						</mat-form-field>
					</ng-container>
					<ng-container *ngSwitchCase="'people'">
						<app-people
							[status]="task.status"
							[title]="item.key"
							[form]="$any(item.value)"
							[showOccupation]="true"></app-people>
					</ng-container>
					<ng-container *ngSwitchCase="'address'">
						<app-group-title [Boundaries]="item.key" [status]="task.status">
							<app-address
								[title]="item.key"
								[form]="$any(item.value)"></app-address>
						</app-group-title>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>{{ item.key }}</mat-label>
							<input matInput [formControl]="$any(item.value)" />
						</mat-form-field>
					</ng-container>
					<mat-icon [matTooltip]="dataControl(item.key)?.description || ''"
						>info</mat-icon
					>
				</ng-container>
			</div>

			<div class="actions" *ngIf="!task.hasChildren">
				<button
					[disabled]="
						saving || taskFormGroup.disabled || taskFormGroup.pristine
					"
					mat-raised-button
					color="primary"
					type="button"
					(click)="reset()">
					Reset
				</button>
				<button
					[disabled]="taskFormGroup.disabled || taskFormGroup.pristine"
					mat-raised-button
					color="accent"
					type="button"
					(click)="save()">
					<span *ngIf="!saving">Save</span>
					<span *ngIf="saving"
						>Saving<app-loading-dots></app-loading-dots
					></span>
				</button>
				<button
					*ngIf="
						task?.status != TaskStatus.completed && task?.children?.length == 0
					"
					[disabled]="
						saving ||
						taskFormGroup.disabled ||
						!taskFormGroup.valid ||
						!quickStartQuestionsCompleted ||
						taskHasWarnings(task)
					"
					mat-raised-button
					color="primary"
					type="submit">
					Complete
				</button>
			</div>
		</form>
	</mat-card-content>
</mat-card>

<app-cc-searches
	*ngIf="
		task?.searchOffering == 1 &&
		(task?.status == TaskStatus.notStarted ||
			task?.status == TaskStatus.inProgress)
	"
	[disabled]="task?.status == TaskStatus.disabled"
	[taskId]="task?.id"></app-cc-searches>

<mat-card
	appearance="outlined"
	class="tasks"
	*ngIf="
		(task?.blockerTasks?.length || 0) > 0 && task?.status == TaskStatus.disabled
	"
	[ngClass]="{
		'card-disabled': task?.status == TaskStatus.disabled,
		'card-completed': task?.status == TaskStatus.completed
	}">
	<mat-card-header>
		<mat-card-title>
			Blocking Tasks ({{ (task?.blockerTasks || []).length }})
		</mat-card-title>
	</mat-card-header>
	<mat-card-content class="not-padding">
		<app-task-lists
			[taskList]="task?.blockerTasks || []"
			[state]="task?.status || TaskStatus.disabled"
			(taskCompleted)="completeSubTask($event)"
			(taskInprogress)="InprogressSubTask($event)"
			*ngIf="showHideSubTasks"></app-task-lists>
	</mat-card-content>
</mat-card>

<mat-card
	appearance="outlined"
	class="questions-to-ask"
	*ngIf="
		(task?.questionsTitle?.length || 0) > 0 &&
		(task?.questions?.length || 0) > 0
	"
	[ngClass]="{
		'card-disabled': task?.status == TaskStatus.disabled,
		'card-completed': task?.status == TaskStatus.completed
	}">
	<mat-card-header>
		<mat-card-title>
			{{ task?.questionsTitle }}
		</mat-card-title>
	</mat-card-header>
	<mat-card-content class="not-padding">
		<div class="small-padding" style="font-style: italic">
			This is not a complete list of question but meant to help you start the
			conversation
		</div>
		<ul>
			<li
				style="margin-bottom: 1rem; font-weight: 500"
				*ngFor="let _question of task?.questions; let i = index">
				{{ _question }}
			</li>
		</ul>
	</mat-card-content>
</mat-card>

<mat-card
	appearance="outlined"
	class="terms"
	*ngIf="(task?.terms?.length || 0) > 0"
	[ngClass]="{
		'card-disabled': task?.status == TaskStatus.disabled,
		'card-completed': task?.status == TaskStatus.completed
	}">
	<mat-card-header>
		<mat-card-title> Useful Terms To Understand </mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<!-- <div style="font-style: italic">
			This is not a complete list of question but meant to help you start the
			conversation
		</div> -->
		<div
			style="margin-bottom: 1rem"
			*ngFor="let term of task?.terms; let i = index">
			<div style="font-weight: 500">{{ term.term }} :</div>
			<div>
				{{ term.definition }}
			</div>
		</div>
		<div>
			<a
				href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/829864/6.5492_-_MHCLG_-_How_to_Buy_Guide_WEB.PDF"
				>Source</a
			>
		</div>
	</mat-card-content>
</mat-card>

<app-task-aml
	class="aml"
	*ngIf="(task?.AMLs || []).length > 0"
	[state]="task?.status"
	[AMLs]="task?.AMLs">
</app-task-aml>

<mat-card
	*ngFor="let frame of task?.iFrames; let i = index"
	appearance="outlined"
	class="tasks"
	[ngClass]="{
		'card-disabled': task?.status == TaskStatus.disabled,
		'card-completed': task?.status == TaskStatus.completed
	}">
	<mat-card-header>
		<mat-card-title>
			{{ frame.title }}
		</mat-card-title>
	</mat-card-header>
	<mat-card-content style="display: flex; justify-content: center">
		<!-- <div style="font-style: italic">
			This is not a complete list of question but meant to help you start the
			conversation
		</div> -->
		<iframe
			style="max-width: 500px"
			*ngIf="frame.url && frame.url.length > 0 && !frame.completed"
			[src]="frame.url || '' | safe"
			width="100%"
			height="700px"
			frameborder="0"
			scrolling="no"
			allow="camera; microphone"></iframe>
		<div *ngIf="frame.completed">
			<mat-icon>verified</mat-icon>
			<span style="font-size: 1.3rem; font-weight: 500; vertical-align: super"
				>Completed</span
			>
		</div>
	</mat-card-content>
</mat-card>

<mat-card
	appearance="outlined"
	*ngIf="(task?.children?.length || 0) > 0"
	class="tasks"
	[ngClass]="{
		'card-disabled': task?.status == TaskStatus.disabled,
		'card-completed': task?.status == TaskStatus.completed
	}">
	<mat-card-header>
		<mat-card-title>Tasks ({{ (task?.children || []).length }})</mat-card-title>
	</mat-card-header>
	<mat-card-content class="not-padding">
		<app-task-lists
			[state]="task?.status || TaskStatus.notStarted"
			[isAgent]="userIsAgent()"
			[taskList]="task?.children || []"
			(taskCompleted)="completeSubTask($event)"
			(taskInprogress)="InprogressSubTask($event)"
			(taskNotStarted)="NotStartedSubTask($event)"
			*ngIf="showHideSubTasks"></app-task-lists>
	</mat-card-content>
</mat-card>

<app-inquiries
	class="inquiries"
	*ngIf="!task?.children?.length || task?.children?.length == 0"
	[taskId]="taskId"
	[state]="task?.status"></app-inquiries>

<app-people-form
	class="assignees"
	*ngIf="!loading && (!task?.children?.length || task?.children?.length == 0)"
	[taskId]="taskId"
	[onlyType]="false"
	[chainId]="task?.chainId"
	[propertyId]="task?.propertyId"
	[userType]="task?.assignedToType"
	[state]="task?.status"
	title="Task Assignees"></app-people-form>

<app-documents-form
	class="docs"
	*ngIf="(task?.children?.length || 0) == 0"
	[taskId]="taskId"
	[state]="task?.status"
	[canEdit]="canEdit"
	[documents]="task?.documents || []"></app-documents-form>

<app-viewers
	class="viewers"
	*ngIf="!task?.children?.length || task?.children?.length == 0"
	[state]="task?.status"
	[viewers]="task?.viewers"
	[viewableBy]="task?.viewableBy"></app-viewers>

<app-issues
	class="issues"
	*ngIf="(task?.children?.length || 0) == 0"
	[taskId]="taskId"
	[issues]="task?.warnings || []"
	[issueTypes]="task?.warningTypeSuggestions"
	[state]="task?.status"
	[canEdit]="canEdit"
	(saved)="getTask()"></app-issues>

<div style="height: 2rem"></div>
