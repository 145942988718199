<div class="container">
	<h1 class="header">Settings</h1>
	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-header>
			<mat-card-title>Account Information</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<div class="row">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> First Name </mat-label>
					<input
						matInput
						placeholder="First Name"
						[value]="user?.attributes?.given_name"
						[disabled]="true" />
				</mat-form-field>

				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> Last Name </mat-label>
					<input
						matInput
						placeholder="Last Name"
						[value]="user?.attributes?.family_name"
						[disabled]="true" />
				</mat-form-field>
			</div>
			<div class="row">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> Email </mat-label>
					<input
						matInput
						placeholder="Email"
						[value]="user?.attributes?.email"
						[disabled]="true" />
				</mat-form-field>
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> Phone </mat-label>
					<input matInput placeholder="Phone" [disabled]="true" />
				</mat-form-field>
			</div>
			<div class="row">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> Company </mat-label>
					<input matInput placeholder="Company" [disabled]="true" />
				</mat-form-field>
			</div>
			<div class="row" style="margin: 1rem 0; justify-content: center">
				<button mat-raised-button color="primary" [routerLink]="['templates']">
					Template Preferences
				</button>
			</div>
			<div class="row" style="margin: 1rem 0; justify-content: center">
				<button mat-raised-button color="primary" (click)="subPush()">
					Get Push Subscription
				</button>
			</div>
			<div class="row" style="margin: 1rem 0; justify-content: center">
				<a
					mat-raised-button
					color="primary"
					[routerLink]="['/', 'AMLpermissions']">
					Your Anti-Money Laundering Id Check Permissions
				</a>
			</div>
			<div
				class="row"
				style="margin: 1rem 0; justify-content: center; display: block">
				<label id="example-radio-group-label"
					>What Type Of Searches Should You and Your Clients Use?</label
				>
				<mat-radio-group
					[value]="officialSearches"
					(change)="saveOfficialSearch($event.value)">
					<mat-radio-button value="N">Use Regulated Searches</mat-radio-button>
					<mat-radio-button value="Y">Use Official Searches</mat-radio-button>
				</mat-radio-group>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-header>
			<mat-card-title>Update Your Clear Chain Email</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<div
				style="
					margin-top: 4rem;
					margin-bottom: 4rem;
					gap: 1rem;
					display: flex;
					flex-wrap: wrap;
				">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> Clear Chain Email</mat-label>
					<input
						matInput
						#newCcEmailValue
						placeholder="Bob.Smith.3@mail.ClearChain.house" />
				</mat-form-field>

				<button
					mat-raised-button
					color="primary"
					*ngIf="(newCcEmailValue.value?.length || 0) > 0"
					style="margin-left: 1rem"
					(click)="newCcEmail(newCcEmailValue.value || '')">
					Update Clear Chain Email
				</button>
				<div
					*ngIf="currentCcEmail && currentCcEmail.length > 2"
					style="width: 100%">
					{{ currentCcEmail }}
					<button mat-icon-button (click)="copyCurrentCCEmail()">
						<mat-icon>content_copy</mat-icon>
					</button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-header>
			<mat-card-title>Create/Lookup Clear Chain Emails</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<div
				style="
					margin-top: 4rem;
					margin-bottom: 4rem;
					gap: 1rem;
					display: flex;
					flex-wrap: wrap;
				">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> User Email</mat-label>
					<input matInput #userEmail placeholder="Email" />
				</mat-form-field>
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> First Name</mat-label>
					<input matInput #firstName placeholder="First Name" />
				</mat-form-field>
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> Last Name</mat-label>
					<input matInput #lastName placeholder="Last Name" />
				</mat-form-field>
				<button
					mat-raised-button
					color="primary"
					*ngIf="
						(userEmail.value?.length || 0) > 0 &&
						(firstName.value?.length || 0) > 0 &&
						(lastName.value?.length || 0) > 0
					"
					style="margin-left: 1rem"
					(click)="
						getCcEmail(firstName.value, lastName.value, userEmail.value)
					">
					Create/Lookup Clear Chain Email
				</button>
				<div *ngIf="ccEmail && ccEmail.length > 2">
					{{ ccEmail }}
					<button mat-icon-button (click)="copyCCEmail()">
						<mat-icon>content_copy</mat-icon>
					</button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card appearance="outlined" style="margin: 3rem 0" *ngIf="isAdmin">
		<mat-card-header>
			<mat-card-title>Admin Section</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<div style="height: 4rem">
				<button
					mat-raised-button
					color="primary"
					[routerLink]="['/', 'Admin', 'Inbox']">
					Admin Inbox
				</button>

				<button
					mat-raised-button
					color="primary"
					[routerLink]="['/', 'adminReport']">
					Admin Reports
				</button>
			</div>
			<div class="spoof">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> User Email To View As </mat-label>
					<input
						matInput
						placeholder="Email"
						[value]="this.AuthService.spoofAs || ''"
						(blur)="spoofUser($event.target.value)" />
				</mat-form-field>
				<button
					mat-raised-button
					color="primary"
					*ngIf="(this.AuthService.spoofAs?.length || 0) > 0"
					style="margin-left: 1rem"
					(click)="spoofUser('')">
					Reset
				</button>
			</div>
			<div>
				<div>
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label> Code Starts With </mat-label>
						<input matInput placeholder="BETA" #preCode />
					</mat-form-field>
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label> Percent Off </mat-label>
						<input matInput placeholder="50" type="number" #percentOff />
						<span matTextSuffix>%</span>
					</mat-form-field>
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label> Number Of Codes </mat-label>
						<input
							matInput
							placeholder="1"
							value="1"
							type="number"
							#numberOfCodes />
					</mat-form-field>
					<button
						mat-raised-button
						color="primary"
						*ngIf="percentOff.value.length > 0"
						style="margin-left: 1rem"
						(click)="
							createDiscountCodes(
								numberOfCodes.value,
								preCode.value,
								percentOff.value
							)
						">
						Crate Codes
					</button>
				</div>
				<div>
					<div *ngFor="let code of discounts">
						{{ code }}
					</div>
				</div>
				<br />
				<br />
			</div>
			<div class="test-error">
				<button mat-raised-button color="warn" (click)="testError()">
					Test Error
				</button>
			</div>
			<br />
			<br />
			<div class="spoof">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label> Delete Chain Id </mat-label>
					<input
						matInput
						placeholder="id"
						(input)="deleteChainId = $event.target.value"
						[value]="this.deleteChainId || ''" />
				</mat-form-field>
				<button
					mat-raised-button
					color="primary"
					*ngIf="(deleteChainId?.length || 0) > 0"
					style="margin-left: 1rem"
					(click)="deleteChain()">
					Delete Chain
				</button>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-header>
			<mat-card-title>Notifications</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<table width="100%">
				<tr
					[app-notification-field]
					Conveyancer="When a task I'm assigned to is unblocked"
					[formControl]="$any(tasksUnblocked.get('ass'))"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksUnblocked.get('track'))"
					Conveyancer="When a task on my track is unblocked"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksUnblocked.get('prop'))"
					Conveyancer="When a task on my property is unblocked"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksUnblocked.get('chain'))"
					Conveyancer="When a task on my chain is unblocked"></tr>

				<tr
					[app-notification-field]
					Conveyancer="When a task I'm assigned to is completed"
					[formControl]="$any(tasksCompleted.get('ass'))"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksCompleted.get('track'))"
					Conveyancer="When a task on my track is completed"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksCompleted.get('prop'))"
					Conveyancer="When a task on my property is completed"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksCompleted.get('chain'))"
					Conveyancer="When a task on my chain is completed"></tr>

				<tr
					[app-notification-field]
					Conveyancer="When a task I'm assigned to is updated"
					[formControl]="$any(tasksUpdated.get('ass'))"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksUpdated.get('track'))"
					Conveyancer="When a task on my track is updated"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksUpdated.get('prop'))"
					Conveyancer="When a task on my property is updated"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksUpdated.get('chain'))"
					Conveyancer="When a task on my chain is updated"></tr>

				<tr
					[app-notification-field]
					Conveyancer="When a task I'm assigned to is overdue"
					[formControl]="$any(tasksOverdue.get('ass'))"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksOverdue.get('track'))"
					Conveyancer="When a task on my track is overdue"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksOverdue.get('prop'))"
					Conveyancer="When a task on my property is overdue"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksOverdue.get('chain'))"
					Conveyancer="When a task on my chain is overdue"></tr>

				<tr
					[app-notification-field]
					Conveyancer="When a task I'm assigned to is about to be due"
					[formControl]="$any(tasksDueTomorrow.get('ass'))"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksDueTomorrow.get('track'))"
					Conveyancer="When a task on my track is about to be due"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksDueTomorrow.get('prop'))"
					Conveyancer="When a task on my property is about to be due"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(tasksDueTomorrow.get('chain'))"
					Conveyancer="When a task on my chain is about to be due"></tr>

				<tr
					[app-notification-field]
					[formControl]="$any(buyerSellerForm.get('misBuyingProp'))"
					Conveyancer="When a property being bought is missing"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(buyerSellerForm.get('misSellingProp'))"
					Conveyancer="When a property being sold is missing"></tr>

				<tr
					[app-notification-field]
					[formControl]="$any(propForm.get('misBuyer'))"
					Conveyancer="When a properties buyer is missing"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(propForm.get('misSeller'))"
					Conveyancer="When a properties seller is missing"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(propForm.get('misAgent'))"
					Conveyancer="When a properties Estate Agent is missing"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(propForm.get('misSellerCon'))"
					Conveyancer="When a seller's Conveyancer is missing"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(propForm.get('misBuyerCon'))"
					Conveyancer="When a buyer's Conveyancer is missing"></tr>

				<tr
					[app-notification-field]
					[formControl]="$any(chainsForm.get('complete'))"
					Conveyancer="When a Chain complete"></tr>
				<tr
					[app-notification-field]
					[formControl]="$any(chainsForm.get('exComp'))"
					Conveyancer="When an exchange/completion date is changed"></tr>
			</table>
			<div style="display: flex; justify-content: end">
				<button
					mat-raised-button
					[disabled]="!notificationsForm.dirty || savingPrefs"
					(click)="saveNotificationPreferences()">
					<span *ngIf="!savingPrefs">Save</span>
					<span *ngIf="savingPrefs"
						>Loading<app-loading-dots></app-loading-dots
					></span>
				</button>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-header>
			<mat-card-title>Appearance</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<mat-slide-toggle
				[checked]="theme == 'dark-theme'"
				(change)="manualThemeChange($event.checked)"
				>Dark Mode</mat-slide-toggle
			>
		</mat-card-content>
	</mat-card>
</div>
