import { Injectable } from '@angular/core';
import { userType } from '../setup/httpTypes';
import { backendResponse, BackendService } from './backend.service';
import { Person } from './people-backend.service';

@Injectable({
	providedIn: 'root',
})
export class TaskBackendService {
	constructor(public backend: BackendService) {}

	async getOne(
		taskId: string,
		options?: {
			children?: boolean;
			blockers?: boolean;
		}
	): Promise<backendResponse<Task>> {
		let queryString = ``;
		if (options?.children) {
			queryString += `?children=true`;
		}
		if (options?.blockers) {
			queryString += `?blockers=true`;
		}
		return (await this.backend.get(
			`task/${taskId}${queryString}`
		)) as backendResponse<Task>;
	}

	async getList(options?: {
		chainId?: string;
		propertyId?: string;
		buyerSellerId?: string;
		parentId?: string;
		taskType?: TaskType;
		children?: boolean;
		limit?: number;
	}): Promise<backendResponse<Task[]>> {
		let queryString = ``;
		if (options?.chainId) {
			queryString += `?chainId=${options.chainId}`;
		}
		if (options?.propertyId) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `propertyId=${options.propertyId}`;
		}
		if (options?.buyerSellerId) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `buyerSellerId=${options.buyerSellerId}`;
		}
		if (options?.parentId) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `taskId=${options.parentId}`;
		}
		if (options?.children) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `children=true`;
		}
		if (options?.taskType) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `taskType=${options.taskType}`;
		}
		if (options?.limit) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `limit=${options.limit}`;
		}

		return (await this.backend.get(`tasks${queryString}`)) as any;
	}

	async getCount(): Promise<backendResponse<number>> {
		return (await this.backend.get(`task/count`)) as backendResponse<number>;
	}

	// async put(task:Partial<Task>): Promise<Task> {
	//   return (await this.backend.put(`task`, task)) as Task;
	// }

	async patch(
		taskId: string,
		task: Partial<Task>,
		options?: {
			children?: boolean;
		}
	): Promise<backendResponse<Task>> {
		let queryString = ``;
		if (options?.children) {
			queryString += `?children=true`;
		}
		return (await this.backend.patch(
			`task/${taskId}${queryString}`,
			task
		)) as backendResponse<Task>;
	}

	async delete(taskId: string): Promise<backendResponse<Task>> {
		return (await this.backend.delete(
			`task/${taskId}?softDelete=true`
		)) as backendResponse<Task>;
	}

	async addNote(
		taskId: string,
		message: string,
		noteType: 'user' | 'system'
	): Promise<backendResponse<Task>> {
		return (await this.backend.put(`task/${taskId}/note`, {
			message,
			noteType,
		})) as backendResponse<Task>;
	}

	async deleteNote(
		taskId: string,
		noteId: string
	): Promise<backendResponse<Task>> {
		return (await this.backend.delete(
			`task/${taskId}/note/${noteId}`
		)) as backendResponse<Task>;
	}

	async updateNote(
		taskId: string,
		noteId: string,
		message: string
	): Promise<backendResponse<Task>> {
		return (await this.backend.patch(`task/${taskId}/note/${noteId}`, {
			message,
		})) as backendResponse<Task>;
	}

	async addWarning(
		taskId: string,
		message: string,
		issueType: string,
		level: 'warning' | 'blocker',
		documents: TaskDocument[] = [],
		dismissedBy?: boolean
	): Promise<backendResponse<Task>> {
		return (await this.backend.put(`task/${taskId}/warning`, {
			message,
			issueType,
			level,
			documents,
			dismissedBy,
		})) as backendResponse<Task>;
	}

	async deleteWarning(
		taskId: string,
		warningId: string
	): Promise<backendResponse<Task>> {
		return (await this.backend.delete(
			`task/${taskId}/warning/${warningId}`
		)) as backendResponse<Task>;
	}

	async updateWarning(
		taskId: string,
		warningId: string,
		update: {
			message?: string;
			issueType?: string;
			level?: 'warning' | 'blocker';
			documents?: TaskDocument[];
			dismissedBy?: string;
		}
	): Promise<backendResponse<Task>> {
		return (await this.backend.patch(
			`task/${taskId}/warning/${warningId}`,
			update
		)) as backendResponse<Task>;
	}

	async getDocumentUploadUrl(options: {
		taskId: string;
		fileName: string;
		contentType: string;
		issueId?: string;
	}): Promise<
		backendResponse<{ url: string; id: string; fields: { key: any } }>
	> {
		return (await this.backend.put(`document`, options)) as any;
	}

	async getDocumentDownloadUrl(
		taskId: string,
		documentId: string
	): Promise<backendResponse<string>> {
		return (await this.backend.get(`document/${taskId}/${documentId}`)) as any;
	}

	async getTemplates(): Promise<backendResponse<Task[]>> {
		return (await this.backend.get(`templates`)) as any;
	}

	async patchTemplatePreferences(
		templateId: string,
		update: Partial<Task>
	): Promise<backendResponse<Task>> {
		return (await this.backend.patch(`template/${templateId}`, update)) as any;
	}

	async postCompleteTask(
		taskId: string,
		status: TaskStatus
	): Promise<backendResponse<any>> {
		return (await this.backend.post(`task/${taskId}/complete`, {
			status,
		})) as any;
	}
}

export enum TaskStatus {
	disabled = 'disabled',
	notStarted = 'notStarted',
	inProgress = 'inProgress',
	completed = 'completed',
	cancelled = 'cancelled',
}

export enum TaskResult {
	success = 'success',
	revisionNeeded = 'revisionNeeded',
	yes = 'yes',
	no = 'no',
	failure = 'failure',
}

export enum TaskType {
	starter = 'starter',
	standard = 'standard',
	custom = 'custom',
	milestone = 'milestone',
	decider = 'decider',
	standardSubTask = 'standardSubTask',
}

export interface TaskNote {
	id: string;
	message: string;
	noteType: 'user' | 'system';
	date: Date;
	createdByEmail: string;
}

export interface TaskDocument {
	name: string;
	id: string;
	date: Date;
	taskId?: string;
}

export interface TaskWarning {
	id: string;
	issueType: string;
	message: string;
	level: 'warning' | 'blocker';
	date: Date;
	createdByEmail: string;
	dismissedBy?: string;
	documents?: TaskDocument[];
}

export interface InitSubTasks {
	initTemplateIds: string[];
	enableTemplateIds: string[];
	questions: {
		question: string;
		result?: string;
		completed?: boolean;
		options: {
			label: string;
			description?: string;
			initTemplateIds: string[];
			enableTemplateIds: string[];
		}[];
	}[];
}

export type TaskActionLevel = 'parent' | 'child' | 'sibling' | 'path';

export interface TaskActionPath {
	topLocation:
		| 'Property'
		| 'SellingProperty'
		| 'BuyingProperty'
		| userType.buyer
		| userType.seller
		| userType.buyerSeller;
	taskPath: string[];
}
export interface TaskAction {
	createTask?: {
		templateId: string;
		taskIds?: string[];
		level: TaskActionLevel;
		path?: TaskActionPath;
	};
	enableTask?: {
		templateId: string;
		taskIds?: string[];
		level: TaskActionLevel;
		path?: TaskActionPath;
	};
}

export enum TaskParentType {
	Task = 'Task',
	Property = 'Property',
	BuyerSeller = 'BuyerSeller',
}

export interface linkedPerson {
	email: string;
	userType: userType;
}

export interface Viewer extends Person {
	canEdit: boolean;
}

export interface Task {
	id: string;
	propertyId?: string;
	buyerSellerId?: string;
	chainId: string;
	parentId?: string;
	subTaskIds?: Set<string>;
	createdByTaskIds?: Set<string>;
	options?: {
		label?: string;
		description?: string;
		value: TaskResult;
		actions?: TaskAction[];
	}[];
	defaultOption?: TaskResult;
	successActions?: TaskAction[];
	initSubTasks?: InitSubTasks;
	parentType: TaskParentType;
	childrenLate?: boolean;
	taskType: TaskType;
	isTemplate?: 'template';
	isStarter?: string;
	dateCreated: Date;
	dateUpdated: Date;
	dateCompleted?: Date;
	status: TaskStatus;
	result?: TaskResult;
	estimatedCompletionDate?: Date;
	estimatedBizDaysToComplete: number;
	dateStarted?: Date;
	assignedToType?: userType;
	onlyAssignedToCanEdit?: boolean;
	description: string;
	title: string;
	privetTitle: string;
	templatedFrom: string;
	warnings: TaskWarning[];
	warningTypeSuggestions: string[];
	notes: TaskNote[];
	documents: TaskDocument[];
	requiredForCompletion: boolean;
	requiredForMilestone?: boolean;
	milestoneId?: string;
	buyersTrack?: boolean;
	sellersTrack?: boolean;
	manualStatus?: boolean;

	data?: {
		label: string;
		description?: string;
		value?: any;
		required?: boolean;
		dataType:
			| 'string'
			| 'number'
			| 'boolean'
			| 'date'
			| 'options'
			| 'checkList'
			| 'list'
			| 'money'
			| 'person'
			| 'people'
			| 'address'
			| 'text';
		options?: string[];
	}[];

	createdByEmail: string;
	orderBy: Date;

	questionsTitle?: string;
	questions?: string[];
	terms?: {
		term: string;
		definition: string;
	}[];

	enableRequirements: { templateId: string; result: TaskResult }[][];
	completeRequirements: {
		templateId: string;
		result: TaskResult;
	}[][];

	linkedPersons?: linkedPerson[];

	propertyUPRN?: string;
	propertyNickname?: string;
	propertyAddress?: string;
	onProperty?: boolean;
	hasChildren?: boolean;
	children?: Task[];
	blockerTasks?: Partial<Task>[];
	canBeCompleted?: boolean;
	viewableBy?: userType[];
	canBeOpened?: boolean;
	viewers: Viewer[];
	order?: number;
	preferences?: Partial<Task>;
	iFrames?: {
		url?: string;
		title: string;
		userEmail?: string;
		entityId?: string;
		processId?: string;
		type: 'ALR';
		completed?: boolean;
		//height: number;
	}[];
	searchOffering: number;
	AMLids?: string[];
	AMLs?: AMLcheck[];
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AMLcheck, credasStatus } from './AML-backend.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
	constructor(private domSanitizer: DomSanitizer) {}
	transform(url: string) {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
